import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Head } from "react-static"

import Header from "../components/Header/Header"
import Practices from "../components/Practices/Practices"
import Day from "../components/Day"
import Advantages from "../components/Advantages/Advantages"
// import Features from '../components/Features/Features';
import Curriculum from "../components/Curriculum/Curriculum"
import Knowledge from "../components/Knowledge/Knowledge"
import Changes from "../components/Changes/Changes"
import SoundEditor from "../components/SoundEditor/SoundEditor"
import Progress from "../components/Progress/Progress"
import Offline from "../components/Offline/Offline"
import History from "../components/History/History"
import Notifications from "../components/Notifications/Notifications"
import Tracker from "../components/Tracker/Tracker"
import Tiers from "../components/Tiers/Tiers"
// import Includes from '../components/Includes/Includes';
// import Pricing from '../components/Pricing/Pricing'
import Bottom from "../components/Bottom/Bottom"
import Background from "../components/Background"
import FlowerPreloading from "../components/FlowerPreloading"
import AudioPlayer from "../components/AudioPlayer"
import FixedHeader from "../components/FixedHeader/FixedHeader"
// import FixedLogin from "../components/FixedLogin"
// import { PlayerProvider } from '../../api/player';
import LanguagePicker from "../components/LanguagePicker"
import { isAppStoreLanding } from "../../api/helpers"

const SatiLanding = () => {
  const { t } = useTranslation()
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    if (window && window.YandexRotorSettings) {
      window.YandexRotorSettings.IsLoaded = true;
    }
  }, []);

  const scrollCont = useRef(null)
  useEffect(() => {
    const element = scrollCont.current
    const onScroll = () => {
      setScrollTop(element.scrollTop)
    }
    element.addEventListener('scroll', onScroll)
    return () => element.removeEventListener('scroll', onScroll)
  }, [scrollCont]);

  useEffect(() => {
    const script = document.createElement('script');

    script.onload = () => {
      if (window.VK && window.VK.Retargeting) {
        window.VK.Retargeting.Init("VK-RTRG-1531611-cEQPR");
        window.VK.Retargeting.Hit();
      }
    }
  
    script.src = "https://vk.com/js/api/openapi.js?169";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div>
      {/*<PlayerProvider>*/}
      <Head>
        <title>{t("html.title")}</title>
        <meta
          name="description"
          content={t("html.description")}
        />
        <meta
          property="og:description"
          content={t("html.description")}
        />
        <meta
          property="twitter:description"
          content={t("html.description")}
        />
        <meta property="og:title" content="Cielo" />
        <meta property="twitter:title" content="Cielo" />
        <meta
          property="og:image"
          content="https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg"
        />
        <meta
          property="twitter:image"
          content="https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg"
        />
        <meta property="og:site_name" content="Cie,o" />
        <meta property="og:url" content="https://cielomeditation.app" />
        <meta property="twitter:url" content="https://cielomeditation.ru" />
      </Head>
      <Background scrollTop={scrollTop} />
      <div className="App">
        <Overflow ref={scrollCont}>
          <Header scrollTop={scrollTop} />
          <Advantages />
          <Day />
          <Curriculum scrollTop={scrollTop} />
          <Knowledge />
          <Changes scrollTop={scrollTop} />
          <Practices />
          <SoundEditor />
          <Progress scrollTop={scrollTop} />
          <Offline />
          <History scrollTop={scrollTop} />
          <Notifications />
          <Tracker />
          {!isAppStoreLanding ? (
            <Tiers />
          ) : null}
          {/* <Features/> */}
          {/* <Includes/> */}
          {/* <Differences/> */}
          {/* <ForWhom/> */}
          {/* <Faq/> */}
          {/* <Pricing/> */}
          <Bottom />
          <LanguagePicker />
          {/* <FixedLogin /> */}
          <FlowerPreloading />
          <AudioPlayer />
          <FixedHeader scrollTop={scrollTop} />
        </Overflow>
      </div>
      {/*</PlayerProvider>*/}
    </div>
  )
}

const Overflow = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export default SatiLanding
