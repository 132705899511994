import React from 'react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Flex, Text } from 'core/ui/atoms';
import { withPlayer } from '../../../api/player';
import useViewport from '../../../ui/responsiveContext';
import clamp from 'lodash/clamp';

import PLAY_ICON from 'core/assets/images/icons/play_huge.png';
import PAUSE_ICON from 'core/assets/images/icons/pause_huge.png';

const getPct = (value, radius, offset = 0) => {
  let val = value + offset;
  const c = Math.PI * (radius * 2);
  if (val < 0) {
    val = 0;
  }
  if (val > 100) {
    val = 100;
  }
  return ((100 - val) / 100) * c;
};

const getCoordsByDegree = (degree, radius = 60, isMobile = false) => {
  const radians = degree * (Math.PI / 180);
  const cx = Math.cos(radians) * radius + (isMobile ? 150 : 250);
  const cy = Math.sin(radians) * radius + (isMobile ? 180: 130);
  return [cx, cy];
};

const SvgSection = ({
                      t,
                      value = 30,
                      theoryPercentage = 0.25,
                      isPracticePlaying,
                      practiceProgress,
                      playPractice,
                      pausePractice,
                      seekPractice,
                      ...rest
}) => {
  const { isMobile } = useViewport();

  const [cx1, cy1] = getCoordsByDegree(20, 60, isMobile);
  const [cx2, cy2] = getCoordsByDegree(215, 60, isMobile);
  const [cx3, cy3] = getCoordsByDegree(310, 60, isMobile);
  const time1 = 0.3;
  const time2 = 0.6;
  const time3 = 0.8;

  const setPosition = (dx, dy) => {
    if (!dx && !dy) {
      return;
    }
    const theta = Math.atan2(dy, dx);
    const angle = Math.abs((-360 - (theta * 180) / Math.PI) % 360);
    const seekPercentage = angle / 360;
    seekPractice(seekPercentage);
  };

  if (isMobile) {
    return (
      <MobileWrap>
        <svg viewBox={"0 0 300 480"} xmlSpace="preserve">
          {/* Main circle */}
          <circle
            r="60"
            cx={"150"}
            cy={"180"}
            strokeWidth={2}
            fill="transparent"
            stroke="rgba(255, 255, 255, 0.4)"
            strokeDasharray="376"
            strokeDashoffset="0"
          />
          {/* Progress circle */}
          <circle
            id="bar"
            r="60"
            cx={"150"}
            strokeWidth={2}
            strokeLinecap="round"
            cy={"180"}
            stroke="#ffffff"
            fill="transparent"
            strokeDasharray="376"
            strokeDashoffset={getPct(practiceProgress * 100, 60)}
          />
          {/* Theory circle part */}
          {theoryPercentage ? (
            <circle
              id="bar"
              r="54"
              cx={"150"}
              strokeWidth={1}
              strokeLinecap="round"
              cy={"180"}
              stroke="rgba(255, 255, 255, 0.43)"
              fill="transparent"
              strokeDasharray="376"
              strokeDashoffset={getPct(theoryPercentage * 100, 54, -11)}
            />
          ) : null}

          {/* Transparent circle for interactions */}
          <circle
            r={'60'}
            cx={"150"}
            cy={"180"}
            strokeWidth={4}
            fill="transparent"
            stroke="transparent"
            strokeDasharray="376"
            strokeDashoffset="0"
            onClick={(e) => {
              const { x, y, width, height } = e.currentTarget.getBoundingClientRect();
              const { clientX, clientY } = e;
              const dx = clamp(clientX - x, 0, width);
              const dy = clamp(clientY - y, 0, height);
              const moveX = dx * 200 / width - 100;
              const moveY = dy * 200 / height - 100;
              setPosition(moveX, moveY);
            }}
            style={{
              cursor: 'pointer',
            }}
          />

          {/* Play button */}
          <image
            href={isPracticePlaying ? PAUSE_ICON : PLAY_ICON}
            width={64}
            height={64}
            x={150 - 32}
            y={180 - 32}
            onClick={isPracticePlaying ? pausePractice : playPractice}
            style={{
              cursor: 'pointer',
            }}
          />

          {' Start label '}
          <StartLabel
            x={216}
            y={182}
          >
            Начало
          </StartLabel>

          {/* Part 1 Start */}
          <line
            x1={cx1}
            y1={cy1}
            x2={270}
            y2={240}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <line
            x1={270}
            y1={256}
            x2={270}
            y2={240}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <circle
            r="2.4"
            cx={270}
            cy={256}
            fill="white"
          />
          <foreignObject x="60" y="250" width="200" height="100">
            <Cont justifyContent={'flex-end'} onClick={() => seekPractice(time1)}>
              <SubTitle>{t('Day.partTime1')}</SubTitle>
              <Title paddedLeft>{t('Day.partTitle1')}</Title>
            </Cont>
            <Description style={{ textAlign: 'right' }}>{t('Day.partTextMobile1')}</Description>
          </foreignObject>
          {/* Part 1 End */}
          {/* Part 2 Start */}
          <line
            x1={cx2}
            y1={cy2}
            x2={30}
            y2={180}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <line
            x1={30}
            y1={180}
            x2={30}
            y2={365}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <circle
            r="2.4"
            cx={30}
            cy={365}
            fill="white"
          />
          <foreignObject x="40" y="360" width="200" height="100">
            <Cont onClick={() => seekPractice(time3)}>
              <Title padded>{t('Day.partTitle2')}</Title>
              <SubTitle>{t('Day.partTime2')}</SubTitle>
            </Cont>
            <Description>{t('Day.partTextMobile2')}</Description>
          </foreignObject>
          {/* Part 2 End */}
          {/* Part 3 Start */}
          <line
            x1={cx3}
            y1={cy3}
            x2={270}
            y2={116}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <line
            x1={270}
            y1={116}
            x2={270}
            y2={22}
            stroke={'rgba(255, 255, 255, 0.33)'}
            strokeWidth={0.5}
          />
          <circle
            r="2.4"
            cx={270}
            cy={22}
            fill="white"
          />
          <foreignObject x="60" y="16" width="200" height="100">
            <Cont justifyContent={'flex-end'} onClick={() => seekPractice(time2)}>
              <SubTitle>{t('Day.partTime3')}</SubTitle>
              <Title paddedLeft>{t('Day.partTitle3')}</Title>
            </Cont>
            <Description style={{ textAlign: 'right' }}>{t('Day.partTextMobile3')}</Description>
          </foreignObject>
          {/* Part 3 End */}
        </svg>
      </MobileWrap>
    );
  }

  return (
    <svg viewBox={"0 0 500 260"} xmlSpace="preserve">
      {/* Main circle */}
      <circle
        r="60"
        cx={"250"}
        cy={"130"}
        strokeWidth={2}
        fill="transparent"
        stroke="rgba(255, 255, 255, 0.3)"
        strokeDasharray="376"
        strokeDashoffset="0"
      />
      {/* Progress circle */}
      <circle
        id="bar"
        r="60"
        cx={"250"}
        strokeWidth={2}
        strokeLinecap="round"
        cy={"130"}
        stroke="#ffffff"
        fill="transparent"
        strokeDasharray="376"
        strokeDashoffset={getPct(practiceProgress * 100, 60)}
      />
      {/* Theory circle part */}
      {theoryPercentage ? (
        <circle
          id="bar"
          r="54"
          cx={"250"}
          strokeWidth={1}
          strokeLinecap="round"
          cy={"130"}
          stroke="rgba(255, 255, 255, 0.43)"
          fill="transparent"
          strokeDasharray="376"
          strokeDashoffset={getPct(theoryPercentage * 100, 54, -11)}
        />
      ) : null}

      {/* Transparent circle for interactions */}
      <circle
        r={'60'}
        cx={"250"}
        cy={"130"}
        strokeWidth={4}
        fill="transparent"
        stroke="transparent"
        strokeDasharray="376"
        strokeDashoffset="0"
        onClick={(e) => {
          const { x, y, width, height } = e.currentTarget.getBoundingClientRect();
          const { clientX, clientY } = e;
          const dx = clamp(clientX - x, 0, width);
          const dy = clamp(clientY - y, 0, height);
          const moveX = dx * 200 / width - 100;
          const moveY = dy * 200 / height - 100;
          setPosition(moveX, moveY);
        }}
        style={{
          cursor: 'pointer',
        }}
      />

      {/* Play button */}
      <image
        href={isPracticePlaying ? PAUSE_ICON : PLAY_ICON}
        width={64}
        height={64}
        x={250 - 32}
        y={130 - 32}
        onClick={isPracticePlaying ? pausePractice : playPractice}
        style={{
          cursor: 'pointer',
        }}
      />

      {' Start label '}
      <StartLabel
        x={316}
        y={132}
      >
        {t('Day.start')}
      </StartLabel>

      {/* Part 1 Start */}
      <line
        x1={cx1}
        y1={cy1}
        x2={330}
        y2={160}
        stroke={'rgba(255, 255, 255, 0.3)'}
        strokeWidth={0.5}
      />
      <circle
        r="1.5"
        cx={330}
        cy={160}
        fill="white"
      />
      <foreignObject x="340" y="157" width="100" height="300">
        <Cont onClick={() => seekPractice(time1)}>
          <Title padded>{t('Day.partTitle1')}</Title>
          <SubTitle>{t('Day.partTime1')}</SubTitle>
        </Cont>
        <Description>{t('Day.partText1')}</Description>
      </foreignObject>
      {/* Part 1 End */}
      {/* Part 2 Start */}
      <line
        x1={cx2}
        y1={cy2}
        x2={150}
        y2={50}
        stroke={'rgba(255, 255, 255, 0.33)'}
        strokeWidth={0.5}
      />
      <circle
        r="1.5"
        cx={150}
        cy={50}
        fill="white"
      />
      <foreignObject x="40" y="46" width="100" height="300">
        <Cont justifyContent={'flex-end'} onClick={() => seekPractice(time2)}>
          <SubTitle>{t('Day.partTime2')}</SubTitle>
          <Title paddedLeft>{t('Day.partTitle2')}</Title>
        </Cont>
        <Description style={{ textAlign: 'right' }}>{t('Day.partText2')}</Description>
      </foreignObject>
      {/* Part 2 End */}
      {/* Part 3 Start */}
      <line
        x1={cx3}
        y1={cy3}
        x2={350}
        y2={30}
        stroke={'rgba(255, 255, 255, 0.33)'}
        strokeWidth={0.5}
      />
      <circle
        r="1.5"
        cx={350}
        cy={30}
        fill="white"
      />
      <foreignObject x="360" y="26" width="100" height="300">
        <Cont onClick={() => seekPractice(time3)}>
          <Title padded>{t('Day.partTitle3')}</Title>
          <SubTitle>{t('Day.partTime3')}</SubTitle>
        </Cont>
        <Description>{t('Day.partText3')}</Description>
      </foreignObject>
      {/* Part 3 End */}
    </svg>
  );
};

const Cont = styled(Flex).attrs({
  alignItems: 'center',
})`
  cursor: pointer;
`;

const Title = styled(Text)`
  font-size: 8px;
  color: white;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 8px;
  ${p => p.padded && css`
    margin-right: 10px;
  `}
  ${p => p.paddedLeft && css`
    margin-left: 8px;
  `};
  ${p => p.theme.max('md')`
    font-size: 12px;
    line-height: 12px;
  `};
`;

const SubTitle = styled(Text)`
  font-size: 6px;
  color: white;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: 6px;
  ${p => p.theme.max('md')`
    font-size: 8px;
    line-height: 8px;
  `};
`;

const Description = styled(Text)`
  font-size: 6px;
  color: white;
  font-weight: 200;
  line-height: 8px;
  ${p => p.theme.max('md')`
    font-size: 10px;
    line-height: 12px;
  `};
`;

const StartLabel = styled.text`
  fill: white;
  font-size: 6px;
  opacity: 0.5;
  font-weight: 400;
  ${p => p.theme.max('md')`
    font-size: 7px;
  `};
`;

const MobileWrap = styled.div`
  width: 100%;
  max-width: 449px;
  margin-left: auto;
  margin-right: auto;
`;

export default withTranslation()(withPlayer(SvgSection));
